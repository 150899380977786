define("@glimmer/encoder", ["exports", "@glimmer/vm"], function (_exports, _vm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.InstructionEncoderImpl = void 0;
  class InstructionEncoderImpl {
    constructor(buffer) {
      this.size = 0;
      this.buffer = buffer;
    }
    encode(type, machine) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }
      if (type > _vm.TYPE_SIZE) {
        throw new Error(`Opcode type over 8-bits. Got ${type}.`);
      }
      let first = type | machine | arguments.length - 2 << _vm.ARG_SHIFT;
      this.buffer.push(first);
      for (const op of args) {
        if (true /* DEBUG */ && typeof op === 'number' && op > _vm.MAX_SIZE) {
          throw new Error(`Operand over 32-bits. Got ${op}.`);
        }
        this.buffer.push(op);
      }
      this.size = this.buffer.length;
    }
    patch(position, target) {
      if (this.buffer[position + 1] === -1) {
        this.buffer[position + 1] = target;
      } else {
        throw new Error('Trying to patch operand in populated slot instead of a reserved slot.');
      }
    }
  }
  _exports.InstructionEncoderImpl = InstructionEncoderImpl;
});